// 清空cookie
export function clearAllCookie() {
  var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
  if (keys) {
      for (var i = keys.length; i--;)
          document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString()
  }
}
// 将file转化成base64
export function fileToBase64(file) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
  });
}

// 文件扩展名匹配正则
export function getFileExtendingName(filename) {
  var reg = /\.[^\.]+$/;
  var matches = reg.exec(filename);
  if (matches) {
    return matches[0];
  }
  return "";
}

//验证表单
export async function validateForm(ref) {
  return new Promise((resolve, reject) => {
    ref.validate(valid => {
      if (valid) {
        resolve();
      }
    });
  });
}

// 将图片获取到转化成base64下载
export function downLoadImg(downloadName, url) {
  const tag = document.createElement("a");
  // 此属性的值就是下载时图片的名称，注意，名称中不能有半角点，否则下载时后缀名会错误
  tag.setAttribute("download", downloadName);

  const image = new Image();
  // 设置 image 的 url, 添加时间戳，防止浏览器缓存图片
  image.src = url + "?time=" + new Date().getTime();
  //重要，设置 crossOrigin 属性，否则图片跨域会报错
  image.setAttribute("crossOrigin", "Anonymous");
  // 图片未加载完成时操作会报错
  image.onload = () => {
    tag.href = getImageDataURL(image);
    tag.click();
  };
}

export function getImageDataURL(image) {
  // 创建画布
  const canvas = document.createElement("canvas");
  canvas.width = image.width;
  canvas.height = image.height;
  const ctx = canvas.getContext("2d");
  // 以图片为背景剪裁画布
  ctx.drawImage(image, 0, 0, image.width, image.height);
  // 获取图片后缀名
  const extension = image.src.substring(image.src.lastIndexOf(".") + 1).toLowerCase();
  // 某些图片 url 可能没有后缀名，默认是 png
  return canvas.toDataURL("image/" + extension, 1);
}

//压缩图片
function clipAndCompressCover({ media, currentWidth, currentHeight, success }) {
  // eslint-disable-next-line no-unused-vars
  const that = this;
  const canvas = document.createElement("canvas");
  const area = canvas.getContext("2d");
  const currentScale = currentWidth / currentHeight;
  const targetScale = 750 / 420;
  let targetWidth = 0;
  let targetHeight = 0;
  // let clipWidth = 0;
  // let clipHeight = 0;
  let quality = 0.95; // 不要用1，会额外增大base64大小。

  // 根据视频宽高，决定截图大小
  if (currentScale >= targetScale) {
    targetHeight = currentHeight > 420 ? 420 : currentHeight;
    targetWidth = targetHeight * currentScale;
  } else {
    targetWidth = currentWidth > 750 ? 750 : currentWidth;
    targetHeight = targetWidth / currentScale;
  }
  // clipWidth = targetWidth > 750 ? 750 : targetWidth;
  // clipHeight = targetHeight > 420 ? 420 : currentHeight;
  // canvas.width = clipWidth;
  // canvas.height = clipHeight;
  canvas.width = targetWidth;
  canvas.height = targetHeight;

  area.drawImage(media, 0, 0, targetWidth, targetHeight);
  var handler = function() {
    const base64 = canvas.toDataURL("image/jpeg", quality);
    getMediaSize({ src: base64 }).then(response => {
      if (response.size / 1024 > 100) {
        quality -= 0.1;

        if (quality > 0.2) {
          handler();
        }
      } else {
        success && success(base64);
      }
    });
  };
  handler();
}

//绘制视频封面图片
// const getVideoCover = ({ url, time, success }) => {
export function getVideoCover({ url, time, success }) {
  const video1 = document.createElement("video");
  video1.src = url;
  video1.crossOrigin = "anonymous"; //处理跨域
  video1.style.cssText = "position:fixed; top:0; left:-100%; visibility:hidden";
  video1.onloadeddata = function() {
    // console.log(url,time,success);
    let currentTime = time; //截图时间点
    video1.addEventListener("timeupdate", function() {
      clipAndCompressCover({
        media: video1,
        currentWidth: video1.videoWidth,
        currentHeight: video1.videoHeight,
        success: function(base64) {
          video1.remove(video1);
          success({
            base64: base64
          });
        }
      });
    });
    video1.currentTime = currentTime < 0 ? 1 : currentTime;
  };
  // edge浏览器必须要追加到dom中，才能顺利执行相关事件。
  document.body.appendChild(video1);
}
// 获取媒体资源的大小，返回一个Promise对象。用于解决无法直接获取视频或图片的文件大小。
function getMediaSize({ src }) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    // eslint-disable-next-line no-unused-vars
    xhr.onreadystatechange = _ => {
      if (xhr.readyState === 4) {
        if ((xhr.status >= 200 && xhr.status < 300) || xhr.status === 304) {
          resolve({
            size: xhr.response.size
          });
        } else {
          reject();
        }
      }
    };

    xhr.open("get", URL.createObjectURL(transformBase64ToBlob(src)));
    xhr.responseType = "blob";
    xhr.send();
  });
}

export function transformBase64ToBlob(base64) {
  let byteString;

  if (base64.split(",")[0].indexOf("base64") >= 0) {
    byteString = window.atob(base64.split(",")[1]);
  } else {
    byteString = unescape(base64.split(",")[1]);
  }

  const mimeString = base64
    .split(",")[0]
    .split(":")[1]
    .split(";")[0];
  const ia = new Uint8Array(byteString.length);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([ia], {
    type: mimeString
  });

  return blob;
}

Array.prototype.remove = function(val) {
  var index = this.indexOf(val);
  if (index > -1) {
    this.splice(index, 1);
  }
};

export function arrRemove(arr, val) {
  var index = arr.indexOf(val);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

// 将base64转换为文件对象
export function dataURLtoFile(dataurl) {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = window.atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  let blob = new File([u8arr], "file", { type: mime });
  return blob;
}
/*异步等待一段时间*/
export function sleep(seconds) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, seconds);
  });
}
// 下载服务器的MP3文件
export const downloadMp3 = filePath => {
  fetch(filePath)
    .then(res => res.blob())
    .then(blob => {
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style.display = "none";
      // 使用获取到的blob对象创建的url
      const url = window.URL.createObjectURL(blob);
      //console.log(blob)
      a.href = url;
      // 指定下载的文件名
      a.download = "视频.mp4";
      a.click();
      document.body.removeChild(a);
      // 移除blob对象的url
      window.URL.revokeObjectURL(url);
    });
};
// 小数变成百分比
export function toPercent(point, num = 2) {
  var str=Number(point*100).toFixed(num);
  str+="%";
  return str;
};
// 文本复制
export function copyText(value) {
  return new Promise((resolve) => {
    var input = document.createElement("input"); // js创建一个input输入框
    input.value = value; // 将需要复制的文本赋值到创建的input输入框中
    document.body.appendChild(input); // 将输入框暂时创建到实例里面
    input.select(); // 选中输入框中的内容
    document.execCommand("Copy"); // 执行复制操作
    document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
    resolve();
  });
}

//终端方法
export function newPage(path, obj, fullscreen = false) {
  if (fullscreen) {
    obj.webviewType = 'fullscreenType';
    obj.dt_page_type = 12;
  } else {
    obj.webviewType = 'userActivitesType';
    obj.dt_page_type = 11;
  }

  let routeData = this.$router.resolve({
    path: path,
    query: obj
  });
  console.log("newPage",routeData.href);
  location.href = routeData.href;
};
export function debounce(fn, delay=600){
  let last = 0;
  return function(){
      let now = Date.now();
      if(now-last>delay){
          fn.apply(this, arguments);
          last=now;
      }
  }
}

export const userArray = [
  {
    viewType: "backButton",
    visibility: 1,
    word: "",
    position: "left",
    color: "255,255,255,1",
  },
  {
    viewType: "statusBar",
    color: "253,119,54,1",
    textStyle: 1,
    position: "top",
  },
  {
    viewType: "titleBar",
    color: "0,0,0,0",
    visibility: 1,
    word: "",
    position: "middle",
  },
  {
    viewType: "titleTxt",
    color: "255,255,255,1",
    visibility: 1,
    word: "",
    position: "middle",
  },
];
export const playerArray = [
  {
    viewType: "backButton",
    visibility: 1,
    word: "",
    position: "left",
    color: "255,255,255,1",
  },
  {
    viewType: "statusBar",
    color: "0,0,0,1",
    textStyle: 1,
    position: "top",
  },
  {
    viewType: "titleBar",
    color: "0,0,0,0",
    visibility: 1,
    word: "",
    position: "middle",
  },
  {
    viewType: "titleTxt",
    color: "255,255,255,1",
    visibility: 1,
    word: "",
    position: "middle",
  },
];




