//所有的api可以直接在此链接下测试
// qiweiniu.com/api/swagger-ui.html#/company/indexUsingGET

import request from "@/utils/request";
// import requestApp from "@/utils/requestApp";
import axios from "axios";

const Url = "https://dtying.com";
//接口管理
export default {
  // 上传接口
  uploadFile: {
    upload: Url + "/master_material/ossFileUpload/upload", // 文件上传，不用解析视频文件
    uploadVideo: Url + "/master_material/ossFileUpload/uploadVideo", // 文件上传，解析视频文件
  },
  // 获取商品
  goods: {
    wedengta: () => {
      return request({
        url: 'https://comm.wedengta.com/?s=accuPoint&f=getValueAddedList&req=GetValueAddedListReq&rsp=GetValueAddedListRsp',
        method: 'post',
        data: {
          stUserInfo: {
            vGUID: [],
            sDUA: '',
            iAccountId: 19343,
            sIMEI: ''
          },
          stAccountTicket: {
            sTicket: 'HQr2kBNX-!qtHyFDiuQerlMY5iRxSZdmXLeqMaVVEUs.'
          }
        }
      })
    },
    chinahxzq: () => {
      return request({
        url: 'https://comm.chinahxzq.com.cn/?s=accuPoint&f=getValueAddedList&req=GetValueAddedListReq&rsp=GetValueAddedListRsp&_=1636008576774',
        method: 'post',
        data: {
          stUserInfo: {
            vGUID: [],
            sDUA: '',
            iAccountId: 19343,
            sIMEI: ''
          },
          stAccountTicket: {
            sTicket: 'HQr2kBNX-!qtHyFDiuQerlMY5iRxSZdmXLeqMaVVEUs.'
          }
        }
      })
    },
    dtsbc: () => {
      return request({
        url: 'https://dttg.dtsbc.com.cn/comm/?s=accuPoint&f=getValueAddedList&req=GetValueAddedListReq&rsp=GetValueAddedListRsp&_=1636008576774',
        method: 'post',
        data: {
          stUserInfo: {
            vGUID: [],
            sDUA: '',
            iAccountId: 19343,
            sIMEI: ''
          },
          stAccountTicket: {
            sTicket: 'HQr2kBNX-!qtHyFDiuQerlMY5iRxSZdmXLeqMaVVEUs.'
          }
        }
      })
    }
  },
  // 获取工具
  getToolList: (params) => {
    return request({
      url: `/master_material/videoConfig/toolList`,
      method: 'post',
      data: params
    })
  },
  // 获取资讯 https://news.wedengta.com/getNews?action=NewsList&seccode=0101600519&startid=0&endid=0&type=1&display_from=detail
  getNews: (params) => {
    return request({
      url: 'https://comm.wedengta.com/?s=news&f=getNews&req=NewsReq&rsp=NewsRsp',
      method: 'post',
      data: {
        eGetSource: 2,
        sStartId: 0,
        sEndId: 0,
        ...params
      }
    })
  },
  // 获取券商列表openAccountList
  getAccountList: (params) => {
    return request({
      url: `/master_material/videoConfig/openAccountList`,
      method: 'post',
      data: params
    })
  },
  // 登陆
  login: {
    getToken: (params) => {
      return request({
        url: `/master_material/system/getToken`,
        method: 'post',
        data: params
      })
    }
  },
  // 互动视频
  interactVideo: {
    videoList: (params) => {
      return request({
        url: `/master_material/multiMedia/videoList`,
        method: 'post',
        data: params
      })
    },
    videoDelete: (params) => {
      return request({
        url: '/master_material/multiMedia/videoDelete',
        method: 'post',
        data: params
      })
    },
    videoUpdate: (params) => {
      return request({
        url: '/master_material/multiMedia/videoUpdate',
        method: 'post',
        data: params
      })
    },
    videoDetail: (params) => {
      return request({
        url: '/master_material/multiMedia/videoDetail',
        method: 'post',
        data: params
      })
    },
    // 发布视频
    publishVideo: (params) => {
      return request({
        url: '/master_material/multiMedia/publishVideo',
        method: 'post',
        data: params
      })
    },
    // 添加视频到互动视频中解析
    addVideoToMultiMedia: (params) => {
      return request({
        url: '/master_material/multiMedia/addVideoToMultiMedia',
        method: 'post',
        data: params
      })
    },
  },
  // 素材中心
  materialCenter: {
    // 新增名片信息
    businessCardCreate: (params) => {
      return request({
        url: '/master_material/material/businessCardCreate',
        method: 'post',
        data: params
      })
    },
    // 删除名片信息
    businessCardDelete: (params) => {
      return request({
        url: '/master_material/material/businessCardDelete',
        method: 'post',
        data: params
      })
    },
    // 查询名片信息详情
    businessCardDetail: (params) => {
      return request({
        url: '/master_material/material/businessCardDetail',
        method: 'post',
        data: params
      })
    },
    // 名片列表
    businessCardList: (params) => {
      return request({
        url: '/master_material/material/businessCardList',
        method: 'post',
        data: params
      })
    },
    // 编辑名片信息
    businessCardUpdate: (params) => {
      return request({
        url: '/master_material/material/businessCardUpdate',
        method: 'post',
        data: params
      })
    },


    // 批量新增分支视频素材
    branchVideoCreate: (params) => {
      return request({
        url: '/master_material/material/branchVideoCreate',
        method: 'post',
        data: params
      })
    },
    // 批量新增互动视频
    uploadVideoByInterface: (params) => {
      return request({
        url: '/master_material/ossFileUpload/uploadVideoByInterface',
        method: 'post',
        data: params
      })
    },
    // 批量删除分支视频素材
    branchVideoDelete: (params) => {
      return request({
        url: '/master_material/material/branchVideoDelete',
        method: 'post',
        data: params
      })
    },
    // 分支视频列表
    branchVideoList: (params) => {
      return request({
        url: '/master_material/material/branchVideoList',
        method: 'post',
        data: params
      })
    },
    // 视频素材列表 /material/videoCenterList
    videoCenterList: (params) => {
      return request({
        url: '/master_material/material/videoCenterList',
        method: 'post',
        data: params
      })
    },
    // videoresultsearch
    videoresultsearch: (params) => {
      return request({
        url: 'https://tanzhiw.com/api/video/videoresultsearch/',
        method: 'get',
        params,
      })
    },
    // 编辑分支视频素材
    branchVideoUpdate: (params) => {
      return request({
        url: '/master_material/material/branchVideoUpdate',
        method: 'post',
        data: params
      })
    },


    // 批量新增图片素材
    picMaterialCreate: (params) => {
      return request({
        url: '/master_material/material/picMaterialCreate',
        method: 'post',
        data: params
      })
    },
    // 批量删除图片素材
    picMaterialDelete: (params) => {
      return request({
        url: '/master_material/material/picMaterialDelete',
        method: 'post',
        data: params
      })
    },
    // 图片素材列表
    picMaterialList: (params) => {
      return request({
        url: '/master_material/material/picMaterialList',
        method: 'post',
        data: params
      })
    },
    // 编辑图片素材
    picMaterialUpdate: (params) => {
      return request({
        url: '/master_material/material/picMaterialUpdate',
        method: 'post',
        data: params
      })
    },

    // 新增投票信息
    signUpInfoCreate: (params) => {
      return request({
        url: '/master_material/material/signUpInfoCreate',
        method: 'post',
        data: params
      })
    },
    // 新增投票信息
    voteInfoCreate: (params) => {
      return request({
        url: '/master_material/material/voteInfoCreate',
        method: 'post',
        data: params
      })
    },
    // 删除投票信息
    voteInfoDelete: (params) => {
      return request({
        url: '/master_material/material/voteInfoDelete',
        method: 'post',
        data: params
      })
    },
    // 投票信息列表
    voteInfoList: (params) => {
      return request({
        url: '/master_material/material/voteInfoList',
        method: 'post',
        data: params
      })
    },
    // 删除报名信息
    signUpInfoDelete: (params) => {
      return request({
        url: '/master_material/material/signUpInfoDelete',
        method: 'post',
        data: params
      })
    },
    // 报名信息列表
    signUpInfoList: (params) => {
      return request({
        url: '/master_material/material/signUpInfoList',
        method: 'post',
        data: params
      })
    },
  },
  // 数据统计
  dataStatistics: {
    // 名片埋点
    businessCardBuriedPoint: (params) => {
      return request({
        url: '/master_material/statistics/businessCardBuriedPoint',
        method: 'post',
        data: params
      })
    },
    // 开户，商品，行情，工具 通用埋点
    commonBuriedPoint: (params) => {
      return request({
        url: '/master_material/statistics/commonBuriedPoint',
        method: 'post',
        data: params
      })
    },
    // 点赞或者分享埋点
    likedOrShareBuriedPoint: (params) => {
      return request({
        url: '/master_material/statistics/likedOrShareBuriedPoint',
        method: 'post',
        data: params
      })
    },
    // 视频数据埋点
    videoBuriedPoint: (params) => {
      return request({
        url: '/master_material/statistics/videoBuriedPoint',
        method: 'post',
        data: params
      })
    },
    // 用户投票
    userVoting: (params) => {
      return request({
        url: '/master_material/material/userVoting',
        method: 'post',
        data: params
      })
    },
    // 用户报名
    userSignUp: (params) => {
      return request({
        url: '/master_material/material/userSignUp',
        method: 'post',
        data: params
      })
    },
    // 视频数据统计
    videoStatistics: (params) => {
      return request({
        url: '/master_material/statistics/videoStatistics',
        method: 'post',
        data: params
      })
    },
    // 视频数据详情
    videoStatisticsDetail: (params) => {
      return request({
        url: '/master_material/statistics/videoStatisticsDetail',
        method: 'post',
        data: params
      })
    },
    // 名片数据统计
    businessCardStatistics: (params) => {
      return request({
        url: '/master_material/statistics/businessCardStatistics',
        method: 'post',
        data: params
      })
    },
    // 名片数据统计详情
    businessCardStatisticsDetail: (params) => {
      return request({
        url: '/master_material/statistics/businessCardStatisticsDetail',
        method: 'post',
        data: params
      })
    },
    // 商品数据统计
    goodsStatistics: (params) => {
      return request({
        url: '/master_material/statistics/goodsStatistics',
        method: 'post',
        data: params
      })
    },
    // 商品数据统计详情
    goodsStatisticsDetail: (params) => {
      return request({
        url: '/master_material/statistics/goodsStatisticsDetail',
        method: 'post',
        data: params
      })
    },

    // 报名数据统计
    signUpStatistics: (params) => {
      return request({
        url: '/master_material/statistics/signUpStatistics',
        method: 'post',
        data: params
      })
    },
    // 报名数据统计详情
    signUpStatisticsDetail: (params) => {
      return request({
        url: '/master_material/statistics/signUpStatisticsDetail',
        method: 'post',
        data: params
      })
    },
    // 投票数据统计
    voteStatistics: (params) => {
      return request({
        url: '/master_material/statistics/voteStatistics',
        method: 'post',
        data: params
      })
    },
  },
  appSlider: {
    //app播放列表
    getInteractiveVideoList: (params) => {
      return axios({
        // url: '/?s=dtlive&f=getInteractiveVideoList&req=GetInteractiveVideoListReq&rsp=GetInteractiveVideoListRsp&encode=1',
        url:'https://shortvideo.mdengta.com/shortvideoserver/getInteractiveVideoList',
        method: 'post',
        data: params
      })
    },
    //视频点赞
    clickLikeVideo: (params) => {
      return axios({
        // url: '/?s=dtlive&f=clickLikeVideo&req=ClickLikeVideoReq&rsp=ClickLikeVideoRsp&encode=1',
        url:'https://shortvideo.mdengta.com/shortvideoserver/clickLikeVideo',
        method: 'post',
        data: params
      })
    },
    //视频转发
    clickShareVideo: (params) => {
      return axios({
        // url: '/?s=dtlive&f=clickShareVideo&req=ClickShareVideoReq&rsp=ClickShareVideoRsp&encode=1',
        url:'https://shortvideo.mdengta.com/shortvideoserver/clickShareVideo',
        method: 'post',
        data: params
      })
    },
    //获取分享视屏
    getShareVideoInfo: (params) => {
      return axios({
        url: "https://dtying.wedengta.com/comm/?s=dycomm&f=getVideoInfo",
        method: 'post',
        data: params
      })
    },
    //关注，取消关注
    clickFocusAutor: (params) => {
      return axios({
        // url: '/?s=dtlive&f=clickLikeVideo&req=ClickLikeVideoReq&rsp=ClickLikeVideoRsp&encode=1',
        url:'https://shortvideo.mdengta.com/shortvideoserver/attention/attentionUser',
        method: 'post',
        data: params
      })
    },
    getFeedUserInfo: (params) => {
      return axios({
        url: 'https://shortvideo.mdengta.com/shortvideoserver/userInfo/getFeedUserInfo',
        method: 'post',
        data: params
      })
    },
    getTalentGoodVideoList: (params) => {
      return axios({
        url: 'https://shortvideo.mdengta.com/shortvideoserver/getTalentGoodVideoList',
        method: 'post',
        data: params
      })
    },
    getTalentAppreciationList: (params) => {
      return axios({
        url: 'https://shortvideo.mdengta.com/shortvideoserver/getTalentAppreciationList',
        method: 'post',
        data: params
      })
    },
    getAttenttionList: (type,id) => {
      return axios({
        url: `https://shortvideo.mdengta.com/shortvideoserver/attention/getAttentionList?followerOrFans=${type}&userId=${id}`,
        method: 'post',
        // data: params
      })
    },
  }
};
