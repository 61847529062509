import Vue from "vue";
import Router from "vue-router";
import { getCookie } from "@/utils/utils.js";

Vue.use(Router);

const constantRouterMap = [
  // {
  //   path: "/WW_verify_a57ZurNshQcmazP6.txt",
  //   name: "verifyTxt",
  //   component: () => import("../../WW_verify_a57ZurNshQcmazP6.txt")
  // },
  // {
  //   path: "/",
  //   name: "layout",
  //   redirect: "/crackedVersion", // 首页重定向
  //   meta: { auth: true },
  //   component: resolve => require(["@/layout/basicLayout.vue"], resolve),
  //   children: [
  //     {
  //       path: "/index",
  //       name: "index",
  //       component: () => import("@/views/Index")
  //     },
  //   ]
  // }
  {
    path: '/',
    name: 'layout',
    meta: { auth: true },
    redirect: '/homePage',
    component: () => import('../layout/basicLayout.vue'),
    children: [
      {
        path: "/home",
        name: "home",
        meta: { auth: true },
        redirect: '/interact_video', // 重定向到互动视频
        component: () => import('../views/Home'),
        children: [
          // 互动视频
          {
            path: "/interact_video",
            name: "interact_video",
            meta: { auth: true },
            redirect: '/interact_video/list', // 重定向到互动视频
            component: () => import('../views/Home/interactVideo'),
            children: [
              {
                path: "/interact_video/list",
                name: "video_list",
                meta: { auth: true },
                component: () => import('../views/Home/interactVideo/VideoList/index.vue'),
              },
              {
                path: "/interact_video/edit",
                name: "edit_video",
                meta: { auth: true },
                component: () => import('../views/Home/interactVideo/EditVideo/index.vue'),
              },
            ],
          },
          // 素材中心
          {
            path: "/material_center",
            name: "material_center",
            meta: { auth: true },
            component: () => import('../views/Home/materialCenter')
          },
          // 我的视频
          {
            path: "/data_statistics",
            name: "data_statistics",
            meta: { auth: true },
            component: () => import('../views/Home/dataStatistics')
          }
        ],
      },
      {
        path: "/login",
        name: "login",
        meta: { auth: true },
        component: () => import('../views/Login')
      },
      {
        path: '/accountInfo',
        name: "accountInfo",
        meta: { auth: true },
        component: () => import('../views/AccountInfo')
      },
    ]
  },
  {
    path: "/homePage",
    name: "homePage",
    meta: { auth: false },
    redirect: '/homePage/index',
    component: () => import('../views/homePage/layout.vue'),
    children: [
      {
        path: "/homePage/index",
        name: "homeIndex",
        meta: { auth: false },
        component: () => import('../views/homePage/index.vue'),
      },
      {
        path: "/homePage/product",
        name: "homeProduct",
        meta: { auth: false },
        component: () => import('../views/homePage/product.vue'),
      },
      {
        path: "/homePage/aboutUs",
        name: "homeAboutUS",
        meta: { auth: false },
        component: () => import('../views/homePage/aboutUs.vue'),
      },
      {
        path: "/homePage/protocol",
        name: "homeProtocol",
        meta: { auth: false },
        component: () => import('../views/homePage/protocol.vue'),
      },
      {
        path: "/homePage/update",
        name: "homeUpdate",
        meta: { auth: false },
        component: () => import('../views/homePage/updateLog.vue'),
      },
    ]
  },
  {
    path: "/dengYing",
    name: "dengYing",
    meta: { auth: false },
    component: () => import('../views/DengYing')
  },
  {
    path: "/user",
    name: "user",
    meta: { auth: false },
    component: () => import('../views/User')
  },
  {
    path: "/friends",
    name: "friends",
    meta: { auth: false },
    component: () => import('../views/Friends')
  },
  {
    path: "/dengYingSingle",
    name: "dengYingSingle",
    meta: { auth: false },
    component: () => import('../views/DengYingSingle')
  },
  {
    path: "/secretProtocol",
    name: "secretProtocol",
    meta: { auth: false },
    component: () => import('../views/SecretProtocol')
  },
  {
    path: "/userAgreement",
    name: "userAgreement",
    meta: { auth: false },
    component: () => import('../views/UserAgreement')
  },
  {
    path: "/shareVideo",
    name: "shareVideo",
    meta: { auth: false },
    component: () => import('../views/ShareVideo')
  },
  {
    path: "/test",
    name: "test",
    meta: { auth: false },
    component: () => import('../views/test')
  },
];

const router = new Router({
  mode: "history",
  linkActiveClass: "active",
  scrollBehavior(to, from, savedPosition) {
    const position = { x: 0, y: 0 };
    if (savedPosition) {
      return savedPosition;
    }
    if (to.matched.some(m => m.meta.scrollToTop)) {
      position.x = 0;
      position.y = 0;
    }
    return position;
  },
  routes: constantRouterMap
});

// const getToken = () => {
//   return getCookie("token");
// };
router.beforeEach((to, from, next) => {
  //根据字段判断是否路由过滤
  if (to.matched.some(record => record.meta.auth)) {
    if (localStorage.loginInfo === undefined || !localStorage.loginInfo) {
      //防止无限循环
      if (to.name === "login") {
        next();
        return;
      }
      next({
        path: "/login"
      });
    } else {
      next()
    }
  } else {
    next();
  }
});

export default router;
