export default {
    namespaced: true,
    state: {
        videoPageNum: 1
    },
    getters: {
        videoPageNum: (state) => {
            return  state.videoPageNum
        },
    },
    mutations: {
        setCurrentPageNum: (state, value) => {
            state.videoPageNum = value
        }
    },
    actions: {},
}
    