export default {
    namespaced: true,
    state: {
        userInfo: {},
        stUserInfo:{}
    },
    getters: {
        userInfo: (state) => {
            return  state.userInfo
        },
        stUserInfo: (state) => {
            return  state.stUserInfo
        },
    },
    mutations: {
        setInfo: (state, value) => {
            state.userInfo = value
        },
        setStUserInfo: (state, value) => {
            console.log("setStUserInfo",value);
            state.stUserInfo = value
        }
    },
    actions: {},
}
    