import axios from "axios";
import { Message } from "element-ui";
const request = axios.create({
  baseURL: "https://dtying.com",
  // baseURL: process.env.VUE_APP_BASE_API,
  timeout: 20 * 1000,
  headers: {
    get: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
    },
    post: {
      "Content-Type": "application/json;charset=utf-8"
    }
  },
  // 是否跨站点访问控制请求
  withCredentials: false,
  transformRequest: [
    (data, headers) => {
      const token = localStorage.getItem("Authorization");
      if (token) {
        headers["Authorization"] = token;
      }
      if (headers["Content-Type"] === "multipart/form-data") {
        return data;
      } else {
        return JSON.stringify(data);
      }
    }
  ]
});

// 响应拦截器
request.interceptors.response.use(response => {
  const status = response.status;
  if (status != 200) {
    return Promise.reject(response);
  }
  const code = response.data.code;
  if (code && (code === 401 || code === 1002)) {
    Message.error(response.data.message)
    setTimeout(() => {
      window.location.href = '/login';
      localStorage.clear()
    }, 800)
    return Promise.reject(response);
  } else {
    return response.data;
  }
});

export default request;
